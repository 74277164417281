// ! Deal stages may not be up to date - use getAllDealStages() for ground truth
import { DealPipeline } from './dealPipeline';
export var DealStages;
(function (DealStages) {
    DealStages["STAGE_A_WAITLIST"] = "86820040";
    DealStages["STAGE_B_NO_FEEDBACK"] = "239289584";
    DealStages["STAGE_C_LOST"] = "79346407";
    DealStages["STAGE_1_0"] = "106687969";
    DealStages["STAGE_1_1"] = "presentationscheduled";
    DealStages["STAGE_1_2"] = "366126823";
    DealStages["STAGE_1_2_H"] = "decisionmakerboughtin";
    DealStages["STAGE_2_0"] = "qualifiedtobuy";
    DealStages["STAGE_2_1"] = "161157362";
    DealStages["STAGE_2_X"] = "193541358";
    DealStages["STAGE_3_0"] = "82021343";
    DealStages["STAGE_3_1"] = "328190686";
    DealStages["STAGE_3_2"] = "228500690";
    DealStages["STAGE_3_3"] = "contractsent";
    DealStages["STAGE_3_4"] = "closedwon";
    DealStages["STAGE_3_5"] = "411754986";
    DealStages["STAGE_4_0"] = "139377878";
    DealStages["STAGE_4_1"] = "79461079";
    DealStages["STAGE_5_0"] = "68105939";
    DealStages["STAGE_5_0_H"] = "187084521";
    DealStages["STAGE_6_0"] = "68105940";
    DealStages["STAGE_6_0_H"] = "272943316";
    DealStages["STAGE_7_0"] = "87084479";
    DealStages["STAGE_7_0_H"] = "166466491";
    DealStages["STAGE_7_1"] = "100591556";
    DealStages["STAGE_7_2"] = "127083968";
    DealStages["STAGE_7_2_H"] = "68105942";
    DealStages["STAGE_7_3"] = "68105941";
    DealStages["STAGE_7_4"] = "208969406";
    DealStages["STAGE_8_0"] = "277872885";
    DealStages["STAGE_8_1"] = "121229010";
    DealStages["STAGE_8_2"] = "145243603";
    DealStages["STAGE_9_0"] = "196693483";
    DealStages["STAGE_9_1"] = "100591557";
})(DealStages || (DealStages = {}));
export var DealOnSiteStages;
(function (DealOnSiteStages) {
    DealOnSiteStages["STAGE_1_0"] = "505209326";
    DealOnSiteStages["STAGE_1_1"] = "581047773";
    DealOnSiteStages["STAGE_1_2"] = "505209331";
    DealOnSiteStages["STAGE_1_3"] = "505209332";
    DealOnSiteStages["STAGE_1_4"] = "508749249";
    DealOnSiteStages["STAGE_2_0"] = "508885960";
    DealOnSiteStages["STAGE_2_1"] = "508885961";
    DealOnSiteStages["STAGE_3_0"] = "508749250";
    DealOnSiteStages["STAGE_3_3"] = "537707768";
    DealOnSiteStages["STAGE_3_4"] = "537661171";
    DealOnSiteStages["STAGE_3_5"] = "537707769";
})(DealOnSiteStages || (DealOnSiteStages = {}));
export const getDealStageShortName = (dealStage) => {
    var _a;
    const stageToName = {
        [DealStages.STAGE_A_WAITLIST]: 'Waitlist',
        [DealStages.STAGE_B_NO_FEEDBACK]: 'No Feedback',
        [DealStages.STAGE_C_LOST]: 'Lost',
        [DealStages.STAGE_1_0]: '1.0',
        [DealStages.STAGE_1_1]: '1.1',
        [DealStages.STAGE_1_2]: '1.2',
        [DealStages.STAGE_1_2_H]: '1.2',
        [DealStages.STAGE_2_0]: '2.0',
        [DealStages.STAGE_2_1]: '2.1',
        [DealStages.STAGE_2_X]: '2.x',
        [DealStages.STAGE_3_0]: '3.0',
        [DealStages.STAGE_3_1]: '3.1',
        [DealStages.STAGE_3_2]: '3.2',
        [DealStages.STAGE_3_3]: '3.3',
        [DealStages.STAGE_3_4]: '3.4',
        [DealStages.STAGE_3_5]: '3.5',
        [DealStages.STAGE_4_0]: '4.0',
        [DealStages.STAGE_4_1]: '4.1',
        [DealStages.STAGE_5_0]: '5.0',
        [DealStages.STAGE_5_0_H]: '5.0',
        [DealStages.STAGE_6_0]: '6.0',
        [DealStages.STAGE_6_0_H]: '6.0 H',
        [DealStages.STAGE_7_0]: '7.0',
        [DealStages.STAGE_7_0_H]: '7.0 H',
        [DealStages.STAGE_7_1]: '7.1',
        [DealStages.STAGE_7_2]: '7.2',
        [DealStages.STAGE_7_2_H]: '7.2 H',
        [DealStages.STAGE_7_3]: '7.3',
        [DealStages.STAGE_7_4]: '7.4',
        [DealStages.STAGE_8_0]: '8.0',
        [DealStages.STAGE_8_1]: '8.1',
        [DealStages.STAGE_8_2]: '8.2',
        [DealStages.STAGE_9_0]: '9.0',
        [DealStages.STAGE_9_1]: '9.1',
    };
    return (_a = stageToName[dealStage]) !== null && _a !== void 0 ? _a : dealStage;
};
export const getOnSiteDealStageShortName = (dealStage) => {
    var _a;
    const stageToName = {
        [DealOnSiteStages.STAGE_1_0]: '1.0',
        [DealOnSiteStages.STAGE_1_1]: '1.1',
        [DealOnSiteStages.STAGE_1_2]: '1.2',
        [DealOnSiteStages.STAGE_1_3]: '1.3',
        [DealOnSiteStages.STAGE_1_4]: '1.4',
        [DealOnSiteStages.STAGE_2_0]: '2.0',
        [DealOnSiteStages.STAGE_2_1]: '2.1',
        [DealOnSiteStages.STAGE_3_0]: '3.0',
        [DealOnSiteStages.STAGE_3_3]: '3.3',
        [DealOnSiteStages.STAGE_3_4]: '3.4',
        [DealOnSiteStages.STAGE_3_5]: '3.5',
    };
    return (_a = stageToName[dealStage]) !== null && _a !== void 0 ? _a : dealStage;
};
export const checkPassedDealStage = (currentDealStage, targetDealStage, dealStages = undefined, dealPipeline) => {
    let stages = dealStages !== null && dealStages !== void 0 ? dealStages : Object.values(!dealPipeline || dealPipeline === DealPipeline.Remote
        ? DealStages
        : DealOnSiteStages);
    const currentStageIndex = stages.indexOf(currentDealStage);
    const targetStageIndex = stages.indexOf(targetDealStage);
    return currentStageIndex >= targetStageIndex;
};
