import { Installation, InstallationType } from 'api/types';
import { format } from 'date-fns';

import { getSectionForPVInstallation } from './getSectionForInstallation';

const parseDateToStringFormat = (date: Date | undefined): string => {
  if (!date || date.getFullYear() === 1970) {
    return 'Not planned';
  } else {
    return format(date, 'dd.MM.yyyy');
  }
};

export const installationResolver = (
  installation: Installation,
): Installation => {
  const selectedInstallationDateRaw: Date | undefined =
    installation.selectedInstallationDate
      ? new Date(installation.selectedInstallationDate)
      : undefined;

  const roofInclination = installation.roofInclination
    ? `${installation.roofInclination}°`
    : '';

  const installationType = installationTypeResolver(installation.dealProducts);

  return {
    id: installation.id ?? '',
    type: installationType,
    pvId: installation.id,

    roofer: installation.roofer,
    responsiblePm: installation.responsiblePm,
    contactPhone: installation.contactPhone,
    dealId: installation.dealId ?? '',
    electricalUpgradeRequested: installation.electricalUpgradeRequested,
    installationTeam: installation.installationTeam ?? [],
    selectedInstallationDate: parseDateToStringFormat(
      selectedInstallationDateRaw,
    ),
    selectedInstallationDateRaw,
    zip: installation.zip ?? '',
    address: installation.address ?? '',
    city: installation.city ?? '',
    kwp: installation.kwp ?? '-',
    numberOfPanels: installation.numberOfPanels ?? '',
    country: installation.country ?? 'Austria',
    roofInclination: roofInclination,
    dachart: installation.dachart ?? '',
    dacheindeckung: installation.dacheindeckung ?? '',
    roofinfo: [
      installation.dachart,
      installation.dacheindeckung,
      roofInclination,
    ]
      .filter(prop => prop)
      .join(', '),
    dealname: installation.dealname,
    dealStage: installation.dealStage,
    numberOfPanelsInstalled: installation.numberOfPanelsInstalled,
    kwpInstalled: installation.kwpInstalled,
    section: getSectionForPVInstallation(
      installation.dealStage,
      selectedInstallationDateRaw,
    ),
    newSealingRequired: installation.newSealingRequired,
    pvPointOfDeliveryDesignation: installation.pvPointOfDeliveryDesignation,
    installationToolkitArray: installation.installationToolkitArray,
    ownershipSticker: installation.ownershipSticker,
    dateOfEnteranceToStage3_5: installation.dateOfEnteranceToStage3_5,
    dateOfEnteranceToStage3_6: installation.dateOfEnteranceToStage3_6,
    provinceName: installation.provinceName,
    onsiteDocumentationApproved: installation.onsiteDocumentationApproved,
    offsiteDocumentationApproved: installation.offsiteDocumentationApproved,
    mapsLink: installation.mapsLink,
    capacityOfEnergyStorageValidated:
      installation.capacityOfEnergyStorageValidated,
    productLine: installation.productLine,
    smartInverterRegistrationNumber:
      installation.smartInverterRegistrationNumber,
    smartEmailAlias: installation.smartEmailAlias,
    solaxCustomerPassword: installation.solaxCustomerPassword,
    gridOperator: installation.gridOperator,
    pvSmartInverterValidated: installation.pvSmartInverterValidated,
    pvInverterType: installation.pvInverterType,
    energyStorageType: installation.energyStorageType,
    contractStatus: installation.contractStatus,
    contractOption: installation.contractOption,
    dealProducts: installation.dealProducts,
    electricalComponents: installation.electricalComponents,
    electricalComponentsInstalled: installation.electricalComponentsInstalled,
    electricalComponentOptions: installation.electricalComponentOptions,
    electricalComponentsStatus: installation.electricalComponentsStatus,
    batteryInstalledInDifferentRoom:
      installation.batteryInstalledInDifferentRoom,
    additionalEUComponentsValidated:
      installation.additionalEUComponentsValidated,
    additionalEUComponentsInstalled:
      installation.additionalEUComponentsInstalled,
  };
};

const installationTypeResolver = (products: string[]): InstallationType => {
  if (products.includes('PV_ES')) {
    return InstallationType.PV_ES_BUNDLE;
  }

  if (products.includes('ES')) {
    return InstallationType.ES_RETROFIT;
  }

  return InstallationType.PV;
};
