var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { hubspotClient } from '../../client';
export const getSmartLineModuleTypeTable = () => __awaiter(void 0, void 0, void 0, function* () {
    const moduleTypeTableRaw = yield hubspotClient.cms.hubdb.rowsApi.getTableRows('28526809', undefined, undefined, undefined, [
        'manufacturer',
        'designation',
        'stc_in_wp',
        'noct_at_in_wp',
        'module_type_property_value',
    ]);
    return moduleTypeTableRaw.results.map(({ id, values }) => ({
        id: id,
        manufacturer: values.manufacturer.name,
        designation: values.designation,
        stcInWp: values.stc_in_wp,
        noctInWp: values.noct_at_in_wp,
        propertyValue: values.module_type_property_value,
    }));
});
